import { SEGMENT_PAGE_TRACK_ROUTS_MAPPING } from 'constants/routes'
import { getProductForSegment } from 'helpers/segment'
import { logExceptionNoConsole } from '../config/sentry'
import { getPathnameFromFullHashUrl } from 'helpers/url'

export const filterIdentifyTraits = (params = {}) => {
  const allowedAttributes = [
    'id',
    'email',
    'languageBrowser',
    'languageApp',
    'mostRecentFullStorySession',
    'userObjective'
  ]

  // return the object with allowed attributes only
  return allowedAttributes.reduce((o, key) => {
    if (key in params) {
      Object.assign(o, { [key]: params[key] })
    }

    return o
  }, {})
}

export const clearSegmentTraits = () => {
  const traitsKey =
    window?.analytics?.options?.user?.localStorage?.key || 'ajs_user_traits'

  localStorage.removeItem(traitsKey)
}

export const sendSegmentIdentity = (userId, extraProps) => {
  if (!userId || !window.analytics) {
    return
  }
  try {
    const getFullStorySessionUrl = window?.FS?.getCurrentSessionURL

    window.analytics.identify(
      userId,
      filterIdentifyTraits({
        id: userId,
        ...extraProps,
        languageBrowser: window.navigator?.language,
        mostRecentFullStorySession:
          typeof getFullStorySessionUrl === 'function'
            ? getFullStorySessionUrl()
            : undefined
      })
    )
  } catch (e) {
    logExceptionNoConsole(e, {
      tags: { source: 'segment' }
    })
  }
}

export const safeSegmentCall = callback => {
  try {
    callback()
  } catch (e) {
    logExceptionNoConsole(e, {
      tags: { source: 'segment' }
    })
  }
}

export const sendSegmentTrackEvent = (eventName, extraProps) => {
  if (eventName) {
    window.analytics?.track(eventName, extraProps)
  }
}

// works as long we are using hash
export const getFullHashUrl = path => `${document.location.origin}/#${path}`

export const getPathData = path => {
  if (SEGMENT_PAGE_TRACK_ROUTS_MAPPING?.[path]) {
    return SEGMENT_PAGE_TRACK_ROUTS_MAPPING[path]
  }

  // try to find the best match based on how similar paths are
  // filter by RegExp hit and sort by length
  const result = Object.keys(SEGMENT_PAGE_TRACK_ROUTS_MAPPING)
    .filter(_path => new RegExp(_path).test(path) === true)
    .sort((a, b) => b.length - a.length)

  if (result.length) {
    return SEGMENT_PAGE_TRACK_ROUTS_MAPPING[result[0]]
  }

  return null
}

export const sendSafeSegmentTrackEvent = (eventName, extraProps) => {
  const pageUrl = window.location.href
  const pathName = getPathnameFromFullHashUrl(pageUrl)
  const pathData = getPathData(pathName)
  const location = pathData?.name || ''

  safeSegmentCall(() => {
    sendSegmentTrackEvent(eventName, {
      pageUrl,
      location,
      product: 'Jungle Scout',
      ...extraProps
    })
  })
}

export const sendSegmentPageEvent = (
  pathname,
  appType = 'default',
  prevPath
) => {
  try {
    const pathData = getPathData(pathname)

    if (pathData) {
      // convertedUrl is the URL without hash so we can use some URL methods and attributes
      const convertedUrl = new URL(document.URL.replace('/#', ''))

      const { search } = convertedUrl

      window.analytics?.page({
        product: getProductForSegment(appType),
        pageName: pathData.name,
        category: pathData.category,
        title: document.title,
        url: document.URL,
        referrer: prevPath ? getFullHashUrl(prevPath) : document.referrer,
        path: pathname,
        search
      })
    }
  } catch (e) {
    logExceptionNoConsole(e, {
      tags: { source: 'segment' }
    })
  }
}

export const sendSegmentTrackAndIdentifyEvents = (
  eventName,
  trackProps,
  userId,
  identifyProps = {}
) => {
  sendSegmentIdentity(userId, identifyProps)
  safeSegmentCall(() => {
    sendSegmentTrackEvent(eventName, trackProps)
  })
}
